import React from "react";

function EvaluationItem(props){
    return(
        <div>
                <br/><img src={props.image} alt="stars"  /><br/>
                {props.dateName}<br/>
                {props.eval}<br/><br/>


        </div>

    );
}

export default EvaluationItem