import React from "react"
import './appMenuCss.css'
//import Row from 'react-bootstrap/Row'
//import Col from 'react-bootstrap/Col'
//import Container from 'react-bootstrap/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faCommentDots, faEnvelope, faGlobe, faBars} from '@fortawesome/fontawesome-free-solid'
import {BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom'
import Content from '../content/Content'
import Localisation  from "../localisation/Localisation"
import {useState} from "react"
 


function Menu(props){
    const [menuDisplayed, setMenuDisplayed]  = useState(true);
    return(
        
        <Router>
            <main>
                {/*
                <Container fluid  className="menuDivId">
                    <Row >
                        <Col  className='menuItemDiv'>&nbsp; &nbsp;  <Link to="/" style={{textDecoration:'none', color:'white'}}> <FontAwesomeIcon icon={faHome}  /> Accueil</Link></Col>
                        <Col  className='menuItemDiv'>&nbsp; &nbsp; <Link to="/localisation" style={{textDecoration:'none', color:'white'}}><FontAwesomeIcon icon={faGlobe} /> Localisation</Link> </Col>
                        <Col  className='menuItemDiv'>&nbsp; &nbsp; <Link to="/evaluation" style={{textDecoration:'none', color:'white'}}><FontAwesomeIcon icon={faCommentDots} /> Evaluations</Link></Col>
                        <Col  className='menuItemDiv'>&nbsp; &nbsp; <Link to="/contact" style={{textDecoration:'none', color:'white'}}><FontAwesomeIcon icon={faEnvelope} /> Contact</Link></Col>
                        
                    </Row>
                </Container>

                */}


                <div  className="menuDivId">

                        {menuDisplayed? <div className='divMenuIconBlack divMenuIconResp'> <span id="hiddingMenuDivForMenu" onClick={()=> setMenuDisplayed(!menuDisplayed)}><b><FontAwesomeIcon icon={faBars}  aria-hidden="true" /> &nbsp;</b></span></div> : <div className='divMenuIconGrey divMenuIconResp'> <span id="hiddingMenuDivForMenu" onClick={()=> setMenuDisplayed(!menuDisplayed)}><b><FontAwesomeIcon icon={faBars}  aria-hidden="true" /> &nbsp;</b></span></div> }
                        
                        {menuDisplayed? <MenuItems/> : null }
                    
                </div>

                <Routes>   
                    <Route path="/" exact element={<Content />}></Route>  
                    <Route path="/evaluation"  element={<Content />}></Route>
                    <Route path="/contact"  element={<Content />}></Route>
                    <Route path="/localisation" element={<Localisation/>}></Route>  
                </Routes> 
            </main>
        </Router>
    );
}



const MenuItems = () => (
    <div>
        <div  className='menuItemDiv menuItemDivResp'>&nbsp; &nbsp;  <Link to="/" style={{textDecoration:'none', color:'white'}}> <FontAwesomeIcon icon={faHome}  /> Accueil</Link></div>
        <div  className='menuItemDiv menuItemDivResp'>&nbsp; &nbsp; <Link to="/localisation" style={{textDecoration:'none', color:'white'}}><FontAwesomeIcon icon={faGlobe} /> Localisation</Link> </div>
        <div  className='menuItemDiv menuItemDivResp'>&nbsp; &nbsp; <Link to="/evaluation" style={{textDecoration:'none', color:'white'}}><FontAwesomeIcon icon={faCommentDots} /> Evaluations</Link></div>
        <div  className='menuItemDiv menuItemDivResp'>&nbsp; &nbsp; <Link to="/contact" style={{textDecoration:'none', color:'white'}}><FontAwesomeIcon icon={faEnvelope} /> Contact</Link></div>
    </div>                   
)



export default Menu