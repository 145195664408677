import React from "react";
//import { Row , Container, Col} from "react-bootstrap";
import InfoItem from "./InfoItem";
import './appInfoDivCss.css';
import modePaiementImage from '../../data/appData/appImages/modePaiement/modePaiement.jpeg'
import qualityImage from '../../data/appData/appImages/quality/quality.jpg'
import halalImage from '../../data/appData/appImages/halal/halal.jpg'
import star5 from '../../data/appData/appImages/star/star5S.jpg'
import star4 from '../../data/appData/appImages/star/star4S.jpg'
import star2 from '../../data/appData/appImages/star/star2S.jpg'

function Info(){

    let modePaiementDescription = "Carte bancaire à partir de 15€" ;
    let qualityDescription ="Notre restaurant vous proposent des repas vriés, préparés soigneusement pour repondre à votre gôut et à vos attentes. Consommées sur place, emportées ou livrées, vos commandes sont traitées par une equipe de professionnels compétents.";
    let halalDescription="Tous nos produit sont certifié Halal par les grandes associations de certification Halal";

    let evals = [
        [ star5, "Le 2022-07-18 19:19:20 ABDELKADER", "bonjour nous venons de commander et cest excellent merci beaucoup" ],
        [ star4, "Le 2022-01-25 22:03:38 Naima Abza", "Je le recommande très bon" ],
        [ star5, "Le 2021-06-02 16:25:27 Hind", "L’assiette que j’ai commandé était vraiment très bonne, le poulet j’ai trop aimé, votre petit pain bien gonflé bien chaud aussi, bref au top. Je me suis régalée, à refaire ! Note : Il faudrait faire des assiettes XL pour les gourmands" ],
        [ star2, "Le 2019-05-05 22:37:18 Morlon", "Nuggets trop cuit donc direction poubelle.kebbab manque de mayo et ketchup,par contre viande pas trop mauvaise et non grasse." ]
    ]

    return(
            <div>
                <InfoItem title="Mode de paiement" image={modePaiementImage}  description={modePaiementDescription} isEvaluation={false} evals={[[]]}></InfoItem>
                <InfoItem title="Qualité" image={qualityImage}  description={qualityDescription} isEvaluation={false} evals={[[]]}></InfoItem>
                <InfoItem title="Halal" image={halalImage}  description={halalDescription} isEvaluation={false} evals={[[]]}></InfoItem>
                <InfoItem title="Evaluation" image={""}  description={""} isEvaluation={true} evals={evals}></InfoItem>
            </div>
            


        
    );

}

export default Info